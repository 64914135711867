
import { Component, Vue, Emit} from 'vue-property-decorator';
import { mixin as Clickaway } from 'vue-clickaway';
import { SweetModal } from 'sweet-modal-vue';
import axiosInstance from '../../../helpers/axios';
import stores from '../../../stores';

@Component({
  components: {SweetModal},
  mixins: [ Clickaway ],
})
export default class ResetPin extends Vue {

  localPin: string = '';
  checkPinSize = false;

  /**
   * Emits the changed pin
   */
  save(): void {
    if (this.localPin.length === 4) {
      this.$emit('save', this.localPin);
      this.localPin = '';
      this.checkPinSize = false;
      this.cancel();
    } else {
      this.checkPinSize = true;
    }
  }

  /**
   * Closes the popup
   */
  cancel(): void {
    // @ts-ignore
    this.$children[0].close();
  }
}
