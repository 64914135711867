
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { mixin as Clickaway } from 'vue-clickaway';
import { SweetModal } from 'sweet-modal-vue';

@Component({
  components: {SweetModal},
  mixins: [ Clickaway ],
})
export default class EditContact extends Vue {
  @Prop()
    con!: any;

  contact: any = null;

  @Watch('con')
    setModuleFromProps() {
      this.contact = JSON.parse(JSON.stringify(this.con));
  }

  remove() {
    if (confirm('Do you really want to remove this customer contact?')) {
      this.$emit('removeContact', this.contact);
    }
  }

  save(): void {
    this.$emit('save', this.contact);
  }
  cancel(): void {
    this.contact = JSON.parse(JSON.stringify(this.con));
    // @ts-ignore
    this.$children[0].close();
  }

}
