
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import SuperAdminHeader from "@/components/partials/super-admin-header.vue";
import stores from "@/stores";
import { showToaster } from "@/components/DashboardComposer/helpers";
import draggable from 'vuedraggable';
import NevronClickConfirm from '@/components/NevronClickConfirm.vue';


@Component({
  components: { SuperAdminHeader, draggable, NevronClickConfirm}
})
export default class Instructions extends Vue {
  categories: any = [];

  editingCategoryLinkIndex: number | null = null;
  editingSubcategoryLinkIndices: { [key: number]: number | null } = {};
  editingCategoryIndex: number | null = null;
  editingSubcategoryIndices: { [key: number]: number | null } = {};
  creatingSubcategoryIndices: { [key: number]: number | null } = {};

  showAddCategoryForm: boolean = false;
  showAddSubCategoryForm: boolean = false;
  showEditInfo: boolean = true;
  removableCategoryId: any = 0;

  newCategory: IInstructions = {
    id: null,
    name: '',
    parentCategory: null,
    sortOrder: 0,
    informationUrl: '',
    subcategories: null,
  }

  newSubCategory: IInstructions = {
    id: null,
    name: '',
    parentCategory: null,
    sortOrder: 0,
    informationUrl: '',
    subcategories: null,
  }

  mounted() {
    this.loadCategories();
  }

  loadCategories() {
    stores.InstructionCategories.fetchData().then(response => {
      this.categories = response.data;
    }).catch(e => {
      showToaster('danger', 'Error', 'Unable to get instructions', 400);
    });
  }
  removeCategoryConfirmation(categoryId: any) {
    this.removableCategoryId = categoryId;
    //@ts-ignore
    this.$refs.modal.openModal();
  }
  removeCategory()
  {
    stores.InstructionCategories.deleteCategory(this.removableCategoryId)
      .then(response => {
        console.log(response);
        if (response.success) {
          showToaster('success', '', 'Category has been removed successfully.');
          this.loadCategories();
        } else {
          showToaster('danger', '', response.error.message)
        }
      });
  }
  toggleAddCategoryForm() {
    this.newCategory.name = '';
    this.newCategory.informationUrl = '';
    this.showAddCategoryForm = !this.showAddCategoryForm;
  }

  toggleAddSubCategoryForm() {
    this.newSubCategory.name = '';
    this.newSubCategory.informationUrl = '';
    this.showAddSubCategoryForm = !this.showAddSubCategoryForm;
  }

  cancelEditCategoryLink(index: number) {
    this.editingCategoryLinkIndex = null;
  }

  toggleEditCategoryLink(index: number) {
    this.editingCategoryLinkIndex = this.editingCategoryLinkIndex === index ? null : index;
  }

  isEditingCategoryLink(index: number) {
    return this.editingCategoryLinkIndex === index;
  }

  saveInstructionCategories() {
    stores.InstructionCategories.updateCategories(this.categories).then(response => {
      showToaster('success', '', 'Instructions are saved');
    });
  }

  cancelEditCategory(index: number) {
    this.editingCategoryLinkIndex = null;
  }

  toggleEditCategory(index: number) {
    this.editingCategoryIndex = this.editingCategoryIndex === index ? null : index;
  }

  isEditingCategory(index: number) {
    return this.editingCategoryIndex === index;
  }

  cancelEditSubcategoryLink(catIndex: number, subIndex: number) {
    // Logic to cancel the edit (revert changes) can be added here
    this.editingSubcategoryLinkIndices[catIndex] = null;
  }

  toggleEditSubcategoryLink(catIndex: number, subIndex: number) {
    if (this.editingSubcategoryLinkIndices[catIndex] === undefined) {
      //ts-ignore
      this.$set(this.editingSubcategoryLinkIndices, catIndex, null);
    }
    this.editingSubcategoryLinkIndices[catIndex] = this.editingSubcategoryLinkIndices[catIndex] === subIndex ? null : subIndex;
  }

  isEditingSubcategoryLink(catIndex: number, subIndex: number) {
    return this.editingSubcategoryLinkIndices[catIndex] === subIndex;
  }

  cancelEditSubcategory(catIndex: number, subIndex: number) {
    // Logic to cancel the edit (revert changes) can be added here
    this.editingSubcategoryIndices[catIndex] = null;
  }

  toggleEditSubcategory(catIndex: number, subIndex: number) {
    if (this.editingSubcategoryIndices[catIndex] === undefined) {
      //ts-ignore
      this.$set(this.editingSubcategoryIndices, catIndex, null);
    }
    this.editingSubcategoryIndices[catIndex] = this.editingSubcategoryIndices[catIndex] === subIndex ? null : subIndex;
  }

  isEditingSubcategory(catIndex: number, subIndex: number) {
    return this.editingSubcategoryIndices[catIndex] === subIndex;
  }

  toggleCreateSubcategory(catIndex: number) {
    if (this.creatingSubcategoryIndices[catIndex] === undefined) {
      //ts-ignore
      this.$set(this.creatingSubcategoryIndices, catIndex, null);
    }
    this.creatingSubcategoryIndices[catIndex] = this.creatingSubcategoryIndices[catIndex] === catIndex ? null : catIndex;
  }

  isCreatingSubcategory(catIndex: number) {
    return this.creatingSubcategoryIndices[catIndex] === catIndex;
  }

  toggleEdit(index: number) {
    if (this.isEditingCategory(index)) {
      this.toggleEditCategory(index);
    }
    this.toggleEditCategoryLink(index);
  }

  onCategoryDragEnd() {
    this.updateSortOrder(this.categories);
  }

  get sortedCategories() {
    return this.categories.sort((a: any, b: any) => a.sortOrder - b.sortOrder);
  }

  onSubcategoryDragEnd(category: any, categoryIndex: number) {
    this.updateSortOrder(this.categories[categoryIndex].subcategories);
  }

  updateSortOrder(items: any[]) {
    items.forEach((item, index) => {
      item.sortOrder = index + 1;
    });
  }

  addNewCategory() {
    if (this.newCategory.name === '' || this.newCategory.name === null) {
      showToaster('danger', '', 'Category name can not be null or empty', 403);
    }
    else {
      stores.InstructionCategories.createNewCategory(this.newCategory)
        .then(response => {
          this.loadCategories();
          this.showAddCategoryForm = false;
          this.newCategory.name = '';
          this.newCategory.informationUrl = '';
          showToaster('success', '', 'Category added successfully', 200);
        }).catch(e => {
        showToaster('danger', '', 'Having error while adding category instruction', 400);
      });
    }
  }

  addNewSubCategory(parentCategoryId: number) {
    if (this.newCategory.name === '' || this.newCategory.name === null) {
      showToaster('danger', '', 'Sub-Category name can not be null or empty', 403);
    }
    else {
      this.newSubCategory.parentCategory = parentCategoryId;
      stores.InstructionCategories.createNewCategory(this.newSubCategory)
        .then(response => {
          this.loadCategories();
          this.toggleCreateSubcategory(parentCategoryId);
          this.newSubCategory.name = '';
          this.newSubCategory.informationUrl = '';
          this.newSubCategory.parentCategory = null;
          showToaster('success', '', 'Sub Category added successfully', 200);
        }).catch(e => {
        showToaster('danger', '', 'Having error while adding category instruction', 400);
      });
    }
  }

  hideEditInfo() {
    this.showEditInfo = false;
  }
}
