
import {Component, Vue, Watch} from 'vue-property-decorator';
import stores from '@/stores';
import TableModalGeneric from '@/components/NevronTableModalGeneric.vue';
import EditActivationData from '@/modules/Accounts/EditActivationCode.vue';
import Skeleton from '@/modules/Skeleton.vue';
import SmallSkeleton from '@/modules/SmallSkeleton.vue';
import NevronHeader from '@/components/ShowNevronComponents/NevronHeader.vue';
import {deletePath} from '../../helpers/DeleteHelper';
import _ from 'lodash';
import NevronClickConfirm from '@/components/NevronClickConfirm.vue';
import Account from '@/stores/Account.ts';
import NevronInput from '@/components/NevronInput.vue';
import NevronFooter from '@/components/ShowNevronComponents/NevronFooter.vue';
import TableGeneric from '@/components/NevronTableGeneric.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';
import {translate} from "@/helpers/functions";

@Component({
  components: {
    TableModalGeneric,
    EditActivationData,
    Skeleton,
    SmallSkeleton,
    NevronHeader,
    NevronClickConfirm,
    NevronInput,
    NevronFooter,
    TableGeneric,
  },
})

export default class AccountDetails extends Vue {

  response: any = null;
  item!: IAccount;
  users:  any = null;
  devices:  any = null;

  /*** device Error handling ***/
  deviceError: boolean = false;
  deviceErrorResponse: any = [];
  failedDevices: IDevice[] = [];
  activationCodeError = false;
  stores = stores;
  defaultAccount: boolean = false;
  defaultAccountValue: any = null;

  /**
   *  Watches for changes in the route
   */
  @Watch('$route', {immediate: true, deep: true})
  changePage() {
    this.refresh(Number(this.$route.params.id));
  }

  /**
   * Sends change to the server when accounts name changes
   */
  onSubmit() {
    const form = this.$refs.form as HTMLFormElement;
    if (!form.checkValidity()) {
      form.classList.add('was-validated');
      showToaster('danger', Vue.prototype.translate('some-fields-are-required'), Vue.prototype.translate('some-fields-are-required'), 422);
      return;
    }
    form.classList.add('was-validated');
    // @ts-ignore
    if (this.item.locked === 1 || this.item.locked === '1' || this.item.locked === true) {
      showToaster('danger', ' ', 'The content is locked by the CMS integration module!', 500);
    } else {
      if (this.item) {
        if (this.item.default) {
          this.defaultAccountValue = this.item.id;
        }
        stores.Setting.setGfAccount(this.defaultAccountValue);
        this.item.users = this.users;
        this.item.devices = this.devices;
        return stores.accounts.updateItem(this.item.id, this.item)
          .then(async (response) => {
            if (response.responseCode === 200) {
              await this.refresh(Number(this.$route.params.id));
              showToaster('success', Vue.prototype.translate(this.item.name), 'successfully updated');
            } else {
              showToaster('danger', Vue.prototype.translate(this.item.name), 'Fail to update', response.code);
            }
          })
          .catch((e: any) => {
            console.error(e);
            showToaster('danger', Vue.prototype.translate(this.item.name), 'Fail to update');
          });
      }
    }

  }

  detachLanguage(language: ILanguage) {
    this.item.languages = this.item.languages.filter((el: ILanguage) => el.id !== language.id);
  }

  // languages
  attachLanguages(languages: ILanguage[]) {
    const newItems = languages.filter((el: ILanguage) => !this.languageIds.includes(el.id));
    this.item.languages = this.item.languages.concat(newItems);
  }

  get languageIds() {
    return this.item.languages.map((el: ILanguage) => el.id);
  }

  /**
   * Removes account
   */
  popoverMethod() {
    if (this.item && this.item.id) {
      return deletePath(this.item.id);
    }
  }

  deleteAccount() {
    // @ts-ignore
    if (this.item.locked === 1 || this.item.locked === '1' || this.item.locked) {
      showToaster('danger', ' ', 'The content is locked by the CMS integration module!', 500);
      // @ts-ignore
    } else if (this.item.default === 1 || this.item.default === '1' || this.item.default) {
      showToaster('danger', ' ', 'Cannot delete default account' + ' ' + Vue.prototype.translate(this.item.name), 500);
    } else {
      stores.accounts.deleteItem(String(this.item?.id!))
        .then((response: any) => {
          if (!response.success) {
            throw new Error(response.error.message);
          }

          showToaster('success', translate(this.item.name), translate('successfully-deleted'));
          this.$router.push({name: 'customers', query: {tab: 'accounts'}});
        });
    }
  }

  /**
   * Sends change to the server when accounts activation code changes
   */
  editActivation(newCode: string) {
    this.activationCodeError = false;
    if (this.item) {
      const data = {
        activationCode: newCode,
      };
      return stores.accounts.updateItem(this.item.id, data)
        .then((response) => {
          this.item!.activationCode = newCode;
          // @ts-ignore
          this.$refs.reset.$children[0].close();
        })
        .catch((e) => {
          const status = e.response.status;
          // activation code taken
          if (Number(status) === 409) {
            this.activationCodeError = true;
          }
        });
    }
  }


  /**
   * Refreshes the data for the currently selected account
   */
  refresh(id: number) {
    stores.accounts.getSelectedAccount(id)
      .then((response: any) => {
        this.response = response;
        this.item = response.data;
        this.users = this.item.users;
        this.devices = this.item.devices;
        stores.Setting.getKeySetting('default_gf_account').then((res: any) => {
          if (Number(res.value) === this.item.id) {
            this.defaultAccount = true;
          }
        });
      })
      .catch((e: any) => {
        console.log(e);
      });
  }

  // packages
  attachPackages(items: any[]) {
    const newItems = items.filter((el: IItem) => !this.packageIds?.includes(el.id));
    this.item.packages = this.item.packages?.concat(newItems);
  }

  get packageIds() {
    return this.item.packages?.map((el: any) => el.id);
  }

  detachPackage(item: IItem) {
    this.item.packages = this.item.packages?.filter((el: any) => el.id !== item.id);
  }

  // devices
  attachDevices(items: any[]) {
    const newItems = items.filter((el: IItem) => !this.deviceIds.includes(el.id));
    this.devices = this.devices.concat(newItems);
  }

  get deviceIds() {
    return this.item.devices.map((el: any) => el.id);
  }

  detachDevice(item: IItem) {
    this.devices = this.devices.filter((el: any) => el.id !== item.id);
  }

  // users
  attachUsers(items: any[]) {
    const newItems = items.filter((el: IItem) => !this.userIds.includes(el.id));
    this.users = this.users.concat(newItems);
  }

  get userIds() {
    return this.item.users.map((el: any) => el.id);
  }

  detachUser(item: IItem) {
    this.users = this.users.filter((el: any) => el.id !== item.id);
  }

}
