// Import dependencies
import Collection from '@/stores/abstract/Collection';
import Model from '@/stores/abstract/Model';
import axiosInstance from '@/helpers/axios';
import { generateLog } from '@/helpers/functions';
import globalConfig from '@/helpers/globalConfig';

const superAdminURL = `${globalConfig.url}/${globalConfig.path}/admin/instruction/categories`;
const adminUrl = '/instruction/categories'

export class Instructions extends Model<IInstructions> implements IInstructions {
    id: number | null = null;
    name = '';
    parentCategory: number | null = null;
    sortOrder = 0;
    informationUrl = '';
    subcategories: IInstructions[] | null = null;
    superAdmin = false;


    constructor(attributes: Partial<IInstructions>, parentCollection?: Collection<Instructions>) {
        super(attributes, parentCollection);
        Object.assign(this, attributes);
    }

    urlRoot(): string {
      if(this.superAdmin) return superAdminURL;
      return adminUrl;
    }
}

export default class InstructionsCollection extends Collection<Instructions> {
    routeName = 'instruction/categories';
    superAdmin = false;
    url(): string {
        if(this.superAdmin) return superAdminURL;
        return adminUrl;
    }

    model(): new (attributes: IInstructions, collection?: Collection<Instructions>) => Instructions {
        return Instructions;
    }

    build(attributes: IInstructions): Instructions {
        return new Instructions(attributes, this);
    }

    fetchData(): Promise<any> {
        return axiosInstance.get(this.url())
            .then((response:any) => response.data)
            .catch((e:Error) => {
                const log = {
                    route: this.routeName,
                    message: e.message,
                };
                generateLog(log);
                return Promise.reject(e);
            });
    }

    createNewCategory(item: IInstructions): Promise<any> {
        return axiosInstance.post(this.url(), item)
            .then((response:any) => response.data)
            .catch((e:Error) => {
                const log = {
                    route: this.routeName,
                    message: e.message,
                };
                generateLog(log);
                return Promise.reject(e);
            });
    }

    updateCategory(item: IInstructions): Promise<any> {
        return axiosInstance.put(`${this.url()}/${item.id}`, item)
            .then((response:any) => response.data)
            .catch((e:Error) => {
                const log = {
                    route: this.routeName,
                    message: e.message,
                };
                generateLog(log);
                return Promise.reject(e);
            });
    }

    updateCategories(items: IInstructions[]){
        return axiosInstance.put(`${this.url()}`, items)
            .then((response:any) => response.data)
            .catch((e:Error) => {
                const log = {
                    route: this.routeName,
                    message: e.message,
                };
                generateLog(log);
                return Promise.reject(e);
            });
    }

    deleteCategory(categoryId: any) {
      return axiosInstance.delete(`${this.url()}/${categoryId}`)
        .then((response:any) => response.data)
        .catch((e:Error) => {
          const log = {
            route: this.routeName,
            message: e.message,
          };
          generateLog(log);
          return Promise.reject(e);
        });

    }
}
