
import {Component, Vue} from 'vue-property-decorator';
import MultiSelect from 'vue-multiselect';
import virtualList from 'vue-virtual-scroll-list';
import stores from '@/stores';
import Axios from 'axios';
import AttachImage from '@/modules/Media/Attach.vue';
import Editor from '@tinymce/tinymce-vue';
import Skeleton from '@/modules/Skeleton.vue';
import NevronHeader from '@/components/ShowNevronComponents/NevronHeader.vue';
import NevronInput from '@/components/NevronInput.vue';

import NevronFooter from '@/components/ShowNevronComponents/NevronFooter.vue';
import CreateCustomer from '@/modules/Customers/Create.vue';
import CreateLocations from '@/modules/PropertyAssets/Locations/Zones/Create.vue';
import moment from 'moment';
import TableModalGeneric from '@/components/NevronTableModalGeneric.vue';
import TableGeneric from '@/components/NevronTableGeneric.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';

const StayStatus = {
  UPCOMING: 'upcoming',
  ACTIVE: 'active',
  PAST: 'past'
};

@Component({
  components: {
    virtualList,
    AttachImage,
    Editor,
    NevronHeader,
    Skeleton,
    // ClassicEditor,
    MultiSelect,
    NevronInput,

    NevronFooter,
    CreateCustomer,
    CreateLocations,
    TableModalGeneric,
    TableGeneric,
  },
})
export default class StaysDetails extends Vue {

  searchQuery: string = '';
  indexList: number[] = [];
  allChecked: boolean = false;

  stays: any = {
    moduleId: 0,
    name: null,
    description: null,
    shortDescription: null,
    logo: null,
    active: false,
    reservationId: null,
    // @ts-ignore
    categories: [],
    displayWelcomeMessage: true,
  };
  categories: any[] = [];

  searchInProgress: boolean = false;
  CancelToken: any = Axios.CancelToken;
  source: any;
  attachableContent: any = null;

  autocompleteItems: any[] = [];
  selectedFolderId: any = 1;
  contentUrl: any = '';
  // editor: any = ClassicEditor;
  imageUrl: any = '';
  autocompleteCatItems: any[] = [];
  catTag: string = '';
  types: any[] = [];
  selectedtypes: any[] = [];
  customerName: any = '';
  minDate: any = '';
  dateCheck: boolean = false;
  stores = stores;
  checkinDate: any = null;
  checkinTime: any = null;
  checkoutDate: any = null;
  checkoutTime: any = null;
  reservationId: any= null;

  changeCheckOutDate() {
    this.dateCheck = moment(this.stays.checkoutAt).isBefore(this.stays.checkinAt);
  }
  setMainCustomer(customer: ICustomer) {
    this.stays.mainCustomer = customer;
  }

  // customers
  attachCustomers(customers: ICustomer[]) {
    const newItems = customers.filter((el: ICustomer) => !this.customerIds.includes(el.id));
    this.stays.customers = this.stays.customers.concat(newItems);
  }

  get customerIds() {
    return this.stays.customers.map((el: ICustomer) => el.id);
  }

  detachCustomer(customer: ICustomer) {
    if(this.stays.mainCustomer.id == customer.id){
      showToaster('danger', 'Main customer cannot be removed from stay','');
    }else{
      this.stays.customers = this.stays.customers.filter((el: ICustomer) => el.id !== customer.id);
    }
  }

  // locations
  attachLocations(locations: IRooms[]) {
    const newItems = locations.filter((el: IRooms) => !this.locationIds.includes(el.id));
    this.stays.locations = this.stays.locations.concat(newItems);
  }

  get locationIds() {
    return this.stays.locations.map((el: IRooms) => el.id);
  }

  detachLocation(location: IRooms) {
    this.stays.locations = this.stays.locations.filter((el: IRooms) => el.id !== location.id);
  }

  next(nextId: string, e: any) {
    e.preventDefault();
    this.$router.push({name: 'stays.items.show', params: {id: nextId}});
    this.getPage(Number(nextId));
  }

  previous(previousId: string, e: any) {
    e.preventDefault();
    this.$router.push({name: 'stays.items.show', params: {id: previousId}});
    this.getPage(Number(previousId));
  }

  get filteredCatItems(): any {
    return this.autocompleteCatItems.filter((i) => {
      return i.text.toLowerCase().indexOf(this.catTag.toLowerCase()) !== -1;
    });
  }

  attach(a: any) {
    // @ts-ignore
    this.stays.tags.push({text: a.tag.text});
    this.catTag = '';
  }

  saveAndReload() {
    if (this.stays.locked === 1 || this.stays.locked === '1' || this.stays.locked === true) {
      showToaster('danger', ' ', 'The content is locked by the CMS integration module!', 500);
    } else {
      if (!this.dateCheck) {
        return stores.Stays.updateItem(Number(this.$route.params.id), this.stays)
          .then((response: any) => {
            this.loadPage();
            this.stays.categories?.forEach((category: ICategories) =>
              category.name = Vue.prototype.translate(category.name));
            if (response.responseCode === 200) {
              showToaster('success', this.stays.mainCustomer ? Vue.prototype.translate(this.stays.mainCustomer.firstName) + ' ' + Vue.prototype.translate(this.stays.mainCustomer.lastName) : 'Main Customer Not Set', 'successfully updated');
            } else {
              showToaster('danger', this.stays.mainCustomer ? Vue.prototype.translate(this.stays.mainCustomer.firstName) + ' ' + Vue.prototype.translate(this.stays.mainCustomer.lastName) : 'Main Customer Not Set', 'Fail to update', response.code);
            }
          })
          .catch((error: any) => {
            console.log(error);
            showToaster('danger', this.stays.mainCustomer ? Vue.prototype.translate(this.stays.mainCustomer.firstName) + ' ' + Vue.prototype.translate(this.stays.mainCustomer.lastName) : 'Main Customer Not Set', 'Fail to update');
          });
      }
    }
  }

  getImages() {
    // @ts-ignore
    this.$refs.image.$children[0].open();
  }

  getImage(id: number) {
    return stores.File.getImage(id)
      .then((response) => {
        this.stays.image = response;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  /**
   * Called when changes need to re-render
   */
  mounted() {
    this.loadPage();
    this.getAutocompleteCategories();
  }

  deleteStay() {
    return stores.Stays.deleteItem(this.stays.id)
      .then((response: any) => {
        this.$router.go(-1);
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  loadPage() {
    this.getPage(Number(this.$route.params.id));
    this.contentUrl = '';
  }

  getPage(id: number) {
    stores.Stays.getDetail(id)
      .then((response) => {
        this.stays = response.data;
        if(this.stays.stayReservationId){
          this.reservationId = this.stays.stayReservationId;
        }
        this.customerName = Vue.prototype.translate(this.stays.mainCustomer.firstName)
          + ' ' + Vue.prototype.translate(this.stays.mainCustomer.lastName);
        this.stays.categories?.forEach((category: ICategories) =>
          category.name = Vue.prototype.translate(category.name));
      })
      .catch((error) => {
        console.log((error));
      });
  }

  attachCategory(selectedCategory: []) {
    console.log(selectedCategory);
    return stores.Stays.attachCatgories(selectedCategory, Number(this.$route.params.id))
      .then((response) => {
        console.log('response', response);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  detachCategory(selectedCategory: []) {
    return stores.Stays.detachCatgories(selectedCategory, Number(this.$route.params.id))
      .then((response) => {
        console.log('response', response);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getAutocompleteCategories() {
    this.CancelToken = Axios.CancelToken;
    this.source = this.CancelToken.source();

    setTimeout(() => {
      return stores.Stays.getAutocompleteCategories(this.searchQuery, this.source.token)
        .then((response) => {
          this.types = response.data;
          this.types.forEach((category: ICategories) =>
            category.name = Vue.prototype.translate(category.name));

          this.searchInProgress = false;
        })
        .catch((e) => {
          console.log(e);
        });
    }, 500);
  }

  status() {
    const now = new Date();
    const checkinDate = new Date(this.stays.checkinAt);
    const checkoutDate = new Date(this.stays.checkoutAt);

    if (checkinDate > now) {
      this.stays.status = StayStatus.UPCOMING;
    } else if (checkinDate <= now && checkoutDate > now) {
      this.stays.status = StayStatus.ACTIVE;
    } else {
      this.stays.status = StayStatus.PAST;
    }
  }

  customersCount() {
    return this.stays.customers.length;
  }

}
